<template lang="pug">
  .documents-manager
      .banner
      v-container
        .new-button
            v-btn.new(color="primary" to="/contracts/new" large) + New contract
        v-card
            v-card-title Filter contracts
            v-card-text
                v-text-field.search-input(v-model="documentParams.search" @input="filterDocument" placeholder="Search by person, artist, or song name")
                    v-icon(slot="prepend") mdi-magnify
                .status-filters
                    v-checkbox(label="Draft" v-model="documentParams.statuses" :value="0" @change="getDocuments")
                    v-checkbox(label="Sent" v-model="documentParams.statuses" :value="1" @change="getDocuments")
                    v-checkbox(label="Read" v-model="documentParams.statuses" :value="2" @change="getDocuments")
                    v-checkbox(label="Signed" v-model="documentParams.statuses" :value="3" @change="getDocuments")
        br
        br
        v-card
          v-card-title Contract list
          v-card-text
            v-data-table(
                :headers="documentHeaders"
                :items="documents"
                :loading="loadingDocuments"
                @update:sort-by="sortDocuments"
                @update:sort-desc="orderDesc"
                :sort-by="documentParams.sort"
                :sort-desc="documentParams.desc"
                :server-items-length="25"
                :items-per-page="25"
                hide-default-footer
                item-key="uuid"
            )
                template(#item.name="{ item }")
                    span(v-if="item.status")
                        router-link.document-name(:to="`/contracts/${item.id}/preview`") {{item.name || '[Untitled]'}}
                    span(v-else)
                        router-link.document-name(:to="`/contracts/${item.id}`") {{item.name || '[Untitled]'}}
                template(#item.signers="{ item }")
                    v-dialog(scrollable v-if="item.signers.length > 0" max-width="650px" )
                        div.signers-list
                            v-card
                                v-card-title Statuses for
                                    strong &nbsp;{{item.name || '[Untitled]'}}
                                v-divider
                                v-card-text
                                    v-row.signer(v-for="signer in item.signers")
                                        v-col(sm="11")
                                            span.icon-holder(:class="statuses.class[signer.status_id]")
                                                v-icon(:class="statuses.class[signer.status_id]" :color="statuses.colorsModal[signer.status_id]") {{statuses.icons[signer.status_id]}}
                                            strong(:class="statuses.class[signer.status_id]") {{ signer.name }}&nbsp;
                                            span {{ statusText[signer.status] }}
                                            div.date {{formatDate(signer.timestamp)}}
                                        v-col.actions(sm="1")
                                            v-tooltip(v-if="signer.status === 'signed'" left color="primary") Download PDF
                                                template(#activator="{ on, attrs }")
                                                    v-icon(v-bind="attrs" v-on="on" color="primary" @click="downloadPdf(signer, item.name)") mdi-file-download-outline
                                            v-tooltip(left color="primary" v-else) Copy sign url
                                                template(#activator="{ on, attrs }")
                                                    v-icon(v-bind="attrs" v-on="on" color="primary" title="Sign url" @click="copyUrl(signer.url)") mdi-link
                        template(#activator="{ on, attrs }")
                            .signers(v-bind="attrs" v-on="on")
                                span.signer-name View {{item.signers.length}} signer
                                    span(v-if="item.signers.length > 1") s
                    .signer-name(v-else) N/A
                template(#item.status="{ item }")
                    .chip-holder
                        v-chip(:color="statuses.colors[item.status]" :class="statuses.class[item.status]" text-color="white")
                            v-avatar(left)
                                v-icon(:class="statuses.class[item.status]") {{statuses.icons[item.status]}}
                            | {{statuses.names[item.status]}}
                template(#item.created_at="{ item }")
                    span.date(v-if="item.created_at") {{formatDate(item.created_at) }}
                    span.n-a(v-else) N/A
                template(#item.updated_at="{ item }")
                    span.date(v-if="item.updated_at") {{formatDate(item.updated_at) }}
                    span.n-a(v-else) N/A
                template(#item.sent_at="{ item }")
                    span.date(v-if="item.sent_at") {{formatDate(item.sent_at) }}
                    span.n-a(v-else) N/A
                template(#item.read_at="{ item }")
                    span.date(v-if="item.read_at") {{formatDate(item.read_at) }}
                    span.n-a(v-else) N/A
                template(#item.signed_at="{ item }")
                    span.date(v-if="item.signed_at") {{formatDate(item.signed_at) }}
                    span.n-a(v-else) N/A
                template(#loading)
                    .table-spinner
                        Spinner
                template(#item.actions="{ item }")
                    .actions-holder
                        v-tooltip(v-if="item.status === 0" left color="secondary") Edit draft
                            template(#activator="{ on, attrs }")
                                v-btn.action-button(color="secondary" :to="`/contracts/${item.id}`" small icon v-bind="attrs" v-on="on")
                                    v-icon.action mdi-pencil
                        v-tooltip(left color="accent" v-if="item.status === 0") Delete draft
                            template(#activator="{ on, attrs }")
                                v-btn.action-button(color="accent" small icon v-bind="attrs" v-on="on" @click="deleteDocument(item.id)")
                                    v-icon.action mdi-trash-can-outline
                        v-tooltip(v-else left color="primary") View contract
                            template(#activator="{ on, attrs }")
                                v-btn.action-button(color="primary" :to="`/contracts/${item.id}/preview`" small icon v-bind="attrs" v-on="on")
                                    v-icon.action mdi-eye-outline
            v-pagination(v-model="page" :length="pages" @input="getPage")
            v-snackbar(v-model="copied" color="green") The contract sign url was copied in your clipboard.

</template>

<script>
import api from '@/api';
import { format } from 'date-fns'
import Spinner from "../../components/Spinner";

export default {
    components: {Spinner},
    data(){
        return {
            documents: [],
            documentsData: {},
            loadingDocuments: true,
            selectedDocuments: [],
            documentFilter: '',
            copied: false,
            documentParams: {
                search: '',
                sort: 'updated_at',
                desc: true,
                statuses: [0, 1, 2, 3],
                paginate: 25,
                page: 1
            },
            page: 1,
            searchTimeout: null,
            interval: null,
            statuses: {
                'colors':['secondary lighten-1', 'primary lighten-1', 'amber lighten-1', 'green lighten-1'],
                'colorsModal':['secondary', 'primary', 'amber', 'green'],
                'names': ['Draft', 'Sent', 'Read', 'Signed'],
                'icons': ['mdi-pencil', 'mdi-email-outline', 'mdi-eye-outline', 'mdi-check'],
                'class': ['draft', 'sent', 'read', 'signed']
            },
            statusText: {
                'sent': 'was sent the contract',
                'read': 'has read the contract',
                'signed': 'has signed the contract'
            },
            documentHeaders: [
                {text: 'ID', value: 'id', sortable: false},
                {text: 'Title', value: 'name', sortable: true},
                {text: 'Signer(s)', value: 'signers', sortable: false},
                {text: 'Status', value: 'status', sortable: true},
                {text: 'Modified', value: 'updated_at', sortable: true},
                {text: 'Sent', value: 'sent_at', sortable: true},
                {text: 'Read', value: 'read_at', sortable: true},
                {text: 'Fully signed', value: 'signed_at', sortable: true},
                {text:'', value: 'actions', class: 'actions', sortable: false}
            ],
            mapStatuses: {
                'draft': 0,
                'sent': 1,
                'read': 2,
                'signed': 3
            }
        }
    },

    methods:{
        filterDocument(){
            return true
        },
        formatDate(date){
            return format(new Date(date), 'MM/dd/yyyy hh:mmaaa')
        },
        getPage(page){
            this.documentParams.page = page;
            this.getDocuments()
        },
        sortDocuments(sort){
            this.documentParams.sort = sort;
            this.getDocuments()
        },
        orderDesc(desc){
            this.documentParams.desc = desc || null;
            this.getDocuments()
        },
        resetFilters(){
            this.documentParams = {
                search: '',
                sort: 'updated_at',
                desc: null,
                paginate: 25,
                page: 1
            };
            this.getDocuments();
        },
        async deleteDocument(id){
            await api.deleteDocument(id);
            await this.getDocuments();
        },
        async getDocuments(){
            this.loadingDocuments = true;
            this.documentsData = await api.getDocuments(this.documentParams);
            this.documents = this.documentsData.data;
            this.loadingDocuments = false;
        },
        async copyUrl(url){
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(url);
            } else if (window.clipboardData) {
                window.clipboardData.setData("Text", url);
            }
            this.copied = true;
        },
        async downloadPdf(signer, name){
            await api.downloadDocumentAsPdf(signer.id, `${name} - ${signer.name}`);
        }
    },
    watch: {
        'documentParams.search'(){
            clearTimeout(this.interval);
            this.interval = setTimeout(() => this.getDocuments(), 500)
        },
        sort(){
            if(this.sort[0] === '-'){
                this.documentParams.desc = true
                this.documentParams.sort = this.sort.substring(1);
            }else{
                this.documentParams.desc = null
                this.documentParams.sort = this.sort;
            }
            this.getDocuments()
        }
    },

    computed: {
      pages(){
          let pages = 1;
          if(this.documentsData.meta){
              pages = this.documentsData.meta.last_page;
          }
          return pages;
      }
    },

    async beforeMount(){
        if(this.$route.params.status){
            this.documentParams.statuses = [this.mapStatuses[this.$route.params.status]]
        }
        this.getDocuments();
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.signers-list{

    .signer{

        strong.draft{
            color: $document-draft-color;
        }

        strong.sent{
            color: $document-sent-color;
        }

        strong.read{
            color: $document-read-color;
        }

        strong.signed{
            color: $document-signed-color;
        }

        .date{
            padding-left: 45px;
            color: $color-gray--50;
        }




        .actions{
            display: flex;
            justify-content: center;

            align-items: center;

            .v-icon{
                display: inline;
                cursor: pointer;
            }
        }

        .icon-holder{
            width: 35px;
            height: 35px;
            display: inline-flex;
            border-radius: 100%;
            margin-right: 10px;
            justify-content: center;
            align-items: center;


            position: relative;
            top: 15px;

            &.sent{
                background-color: $color-primary--bright;
            }

            &.read{
                background-color: $color-amber--bright;
            }

            &.signed{
                background-color: $color-green--bright;
            }
        }
    }

    .v-icon{
    }
}
</style>

<style lang="scss" scoped>
    @import '@/assets/styles/_variables.scss';

    .documents-manager{
        position: relative;

        .new-button{
            display: flex;
            justify-content: flex-end;
            padding-top: 25px;
            padding-bottom: 40px;

            .v-btn{
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }

        .table-spinner{
            display: flex;
            justify-content: center;
            align-items: center;

            height: 350px;
            margin-top: 15px;
        }

        .banner{
            width: 100%;
            height: 350px;
            background-image: url('../../assets/images/documents.jpg');
            position: absolute;
            background-size: cover;
            opacity: 0.1;

            &:before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(209,209,246,0.5) 50%, rgba(255,255,255,1) 100%);
                background: linear-gradient(180deg, rgba(237,94,81,0.25) 0%, rgba(209,209,246,0.5) 50%, rgba(255,255,255,1) 100%);
                backdrop-filter: blur(1px);
            }
        }

        .search-input::v-deep{
            margin-top: 15px;
            padding-top: 0px;
            border: 1px solid $color-gray--50;
            margin-bottom: 30px;

            .v-input__slot{
                margin-bottom: 0px;

                &:before{
                    display: none;
                }

                &:after{
                    display: none;
                }

            }

            .v-text-field__details{
                display: none;
            }
        }

        .status-filters{
            display: flex;
            border-top: 1px solid $color-primary--dark;
            margin-top: 0px;
            padding-top: 10px;

            .v-input--checkbox{
                margin-right: 20px;
            }
        }

        ::v-deep .v-pagination{
            margin-top: 50px;
        }

        .chip-holder{
            width: 100px;
        }

        .v-chip{
            max-width: 32px;
            transition: max-width 0.5s, opacity 0.5s;

            opacity: 1;

            &:hover{
                max-width: 100px;
                opacity: 1;
            }
        }

        .v-avatar{
            margin-left: -8px;
        }

        .v-chip .v-icon{
            color: #FFFFFF;

            &.draft{
                color: $color-secondary--normal;
            }

            &.sent{
                color: $color-primary--normal;
            }

            &.read{
                color: $color-amber--normal;
            }

            &.signed{
                color: $color-green--normal;
            }

            font-size: 20px;
            padding: 16px;
        }

        .v-chip::v-deep{

            &.draft .v-chip__content{
                color: $color-secondary--normal;
            }

            &.sent .v-chip__content{
                color: $color-primary--normal;
            }

            &.read .v-chip__content{
                color: $color-amber--normal;
            }

            &.signed .v-chip__content{
                color: $color-green--normal;
            }
        }
        .n-a{
            color: $color-gray--75;
        }

        .v-icon.action{
            font-size: 21px;
        }

        a.document-name{
            text-decoration: none;
            font-weight: bold;

            &:hover{
                text-decoration: underline;
            }
        }

        .signer-name{
            color: $color-gray--75;
        }

        .signers .signer-name{
            color: $color-primary--normal;
        }

        .action-button{
        }

        .actions-holder{
            text-align: right;
        }
    }
</style>
